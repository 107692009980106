import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div
      style={{
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
      }}
    >
      <h1>Terms of Use</h1>
      <p>
        Welcome to Beautyshelf.com, the website and online service of
        Beautyshelf (together with its affiliates, "Beautyshelf" "we," or "us").
        This page explains the terms by which you may use our service. By
        accessing or using the Beautyshelf services, website and software
        provided through or in connection with the service ("Service"), you
        agree on behalf of yourself and all members of your household and others
        who use this Service under your account ("you" or "your") to be bound by
        this Terms of Use Agreement, the Beautyshelf Privacy Policy, and the
        other applicable rules, policies and terms posted on the Beautyshelf.com
        website or provided with the Service (collectively, this "Agreement"),
      </p>
      <p>
        We reserve the right to amend this Agreement at any time in our sole
        discretion by posting the revised Agreement on the Beautyshelf.com
        website. Your continued use of the Service after any such changes
        constitutes your acceptance of the revised Agreement. This Agreement
        applies to all visitors, users, and others who access the Service
        ("Users").
      </p>
      <h2>1. Use of Our Service </h2>
      <p>
        Beautyshelf provides a place for you to discover, track, and talk about
        beauty products with friends and our community.{' '}
      </p>
      <p>
        {' '}
        You do not have to register in order to visit Beautyshelf. To access
        certain features of the Service, though, including creating "shelves" to
        organize the products you've tried, posting product reviews, you will
        need to register with Beautyshelf and create an account. Your account
        gives you access to the services and functionality that we may establish
        and maintain from time to time and in our sole discretion.{' '}
      </p>
      <p>
        You may never use another member’s account without permission. You are
        responsible for maintaining the confidentiality of your account and
        password and for restricting access to your account, and you agree to
        accept responsibility for all activities that occur under your account
        or password. You agree to notify Beautyshelf immediately of any breach
        of security or unauthorized use of your account. By providing
        Beautyshelf your email address you consent to our using the email
        address in accordance with our
        <Link to="/privacy-policy"> Privacy Policy </Link>.
      </p>
      <p>
        {' '}
        Subject to your compliance with this Agreement and your payment of any
        applicable fees, we grant you a limited, non-exclusive,
        non-transferable, non-sublicensable license to access and make personal
        and non-commercial use of the Service. This license does not include any
        resale or commercial use of any part of the Service, or its contents;
        any collection and use of any product listings, descriptions, reviews or
        other material included in the Service; any derivative use of any part
        of the Service or its contents; any downloading, copying, or other use
        of account information for the benefit of any third party; or any use of
        data mining, robots, or similar data gathering and extraction tools. All
        rights not expressly granted to you in this Agreement are reserved and
        retained by us or our licensors, suppliers, rightsholders, or other
        content providers. No part of the Service may be reproduced, duplicated,
        copied, sold, resold, visited, or otherwise exploited for any commercial
        purpose without our express written consent. You may not frame or
        utilize framing techniques to enclose any trademark, logo, or other
        proprietary information (including images, text, page layout, or form)
        of Beautyshelf without our express written consent. You may not use any
        meta tags or any other "hidden text" utilizing our name or trademarks
        without our express written consent. You may not misuse the Service. You
        may use the Service only as permitted by law. The licenses granted by us
        terminate if you do not comply with this Agreement.{' '}
      </p>
      <p>
        Beautyshelf may permanently or temporarily terminate, suspend, or
        otherwise refuse to permit your access to the Service without notice and
        liability for any reason, including if in Beautyshelf' sole
        determination you violate any provision of this Agreement, or for no
        reason. Upon termination for any reason or no reason, you continue to be
        bound by this Agreement.
      </p>
      <p>
        The Service is subject to scheduled and unscheduled service
        interruptions. All aspects of the Service are subject to change or
        elimination at Beautyshelf sole discretion. You agree that Beautyshelf
        will not be liable to you for any interruption of the Service, delay or
        failure to perform.{' '}
      </p>
      <p>
        You are solely responsible for your interactions with other Beautyshelf
        Users. We reserve the right, but have no obligation, to monitor disputes
        between you and other Users. Beautyshelf shall have no liability for
        your interactions with other Users, or for any User’s action or
        inaction.
      </p>
      <h2>2. User Content</h2>
      <p>
        Some areas of the Service may allow Users to upload, publish, display,
        link to or otherwise make available (hereinafter, "post") reviews,
        comments, and other information ("User Content"). You are solely
        responsible for your User Content. You agree not to post User Content
        that is illegal, obscene, threatening, defamatory, invasive of privacy,
        infringing of intellectual property rights (including publicity rights),
        or otherwise injurious to third parties or objectionable, and does not
        consist of or contain software viruses, political campaigning,
        commercial solicitation, chain letters, mass mailings, or any form of
        "spam" or unsolicited commercial electronic messages. You may not use a
        false e-mail address, impersonate any person or entity, or otherwise
        mislead as to the origin of content. You hereby represent that you are
        the owner of all the copyright rights with respect to, or that you have
        the legal right to post, your User Content, and that you have the power
        to grant the license granted below. Beautyshelf reserves the right to
        monitor, reject and/or remove any User Content at any time. For example,
        Beautyshelf may, but is not obligated to, reject and/or remove any User
        Content that Beautyshelf believes, in its sole discretion, violates
        these provisions.
      </p>
      <p>
        Beautyshelf takes no responsibility and assumes no liability for any
        User Content that you or any other Users or third parties post or send
        over the Service. You understand and agree that any loss or damage of
        any kind that occurs as a result of the use of any User Content that you
        post is solely your responsibility. Beautyshelf is not responsible for
        any public display or misuse of your User Content. You understand and
        acknowledge that you may be exposed to User Content that is inaccurate,
        offensive, indecent, or objectionable, and you agree that Beautyshelf
        shall not be liable for any damages you allege to incur as a result of
        such User Content. Beautyshelf may provide tools for you to remove some
        User Content, but does not guarantee that all or any User Content will
        be removable.
      </p>
      <h2>3. License Grant</h2>
      <p>
        By posting any User Content on the Service, you expressly grant to
        Beautyshelf a nonexclusive, royalty-free, perpetual, irrevocable, and
        fully sublicensable right to use, reproduce, modify, adapt, publish,
        perform, translate, create derivative works from, distribute, and
        display such content throughout the world in any media. You represent
        and warrant that you own or otherwise control all of the rights to the
        content that you post; that the content is accurate; that use of the
        content you supply does not violate this policy and will not cause
        injury to any person or entity; and that you will indemnify Beautyshelf
        for all claims resulting from content you supply.
      </p>
      <h2>4. Our Proprietary Rights</h2>
      <p>
        Except for your User Content, the Service and all materials therein or
        transferred thereby, including, without limitation, software, images,
        text, graphics, illustrations, logos, patents, trademarks, service
        marks, copyrights, photographs, audio, videos, music, and User Content
        (the "Beautyshelf Content"), and all intellectual property Rights
        related thereto, are the exclusive property of Beautyshelf and its
        licensors. Except as explicitly provided herein, nothing in this
        Agreement shall be deemed to create a license in or under any such
        intellectual property Rights, and you agree not to sell, license, rent,
        modify, distribute, copy, reproduce, transmit, publicly display,
        publicly perform, publish, adapt, edit or create derivative works from
        any materials or content accessible on the Service. Use of the
        Beautyshelf Content or materials on the Service for any purpose not
        expressly permitted by this Agreement is strictly prohibited.
      </p>
      <p>
        You may choose to or we may invite you to submit comments or ideas about
        the Service, including without limitation about how to improve the
        Service or our products ("Ideas"). By submitting any Idea, you agree
        that your disclosure is gratuitous, unsolicited and without restriction
        and will not place Beautyshelf under any fiduciary or other obligation,
        and that we are free to use the Idea without any additional compensation
        to you, and/or to disclose the Idea on a non-confidential basis or
        otherwise to anyone. You further acknowledge that, by acceptance of your
        submission, Beautyshelf does not waive any rights to use similar or
        related ideas previously known to Beautyshelf, or developed by its
        employees, or obtained from sources other than you.
      </p>
      <h2>5. Eligibility</h2>
      <p>
        This Service is intended solely for Users who are thirteen (13) years of
        age or older, and any registration, use or access to the Service by
        anyone under 13 is unauthorized, unlicensed, and in violation of this
        Agreement. If you are under 18 years of age you may use the Service only
        if you either are an emancipated minor or possess legal parental or
        guardian consent, and are fully able and competent to enter into the
        terms, conditions, obligations, affirmations, representations, and
        warranties set forth in this Agreement, and to abide by and comply with
        this Agreement.
      </p>
      <h2>6. Indemnity</h2>
      <p>
        You agree to defend, indemnify and hold harmless Beautyshelf and its
        subsidiaries, agents, managers, and other affiliated companies, and
        their employees, contractors, agents, officers and directors, from and
        against any and all claims, damages, obligations, losses, liabilities,
        costs or debt, and expenses (including but not limited to attorney's
        fees) arising from: (i) your use of and access to the Service, including
        any data or content transmitted or received by you; (ii) your violation
        of any term of this Agreement, including without limitation your breach
        of any of the representations and warranties above; (iii) your violation
        of any third-party right, including without limitation any right of
        privacy, publicity rights or intellectual property rights; (iv) your
        violation of any law, rule or regulation of the United States or any
        other country; (v) any claim or damages that arise as a result of any of
        your User Content or any that are submitted via your account; or (vi)
        any other party’s access and use of the Service with your unique
        username, password or other appropriate security code.
      </p>
      <h2>7. Disclaimers of Warranties and Limitation of Liability</h2>
      <p>
        THE SERVICE AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
        SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
        YOU THROUGH THE SERVICE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
        BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. BEAUTYSHELF MAKES NO
        REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
        OPERATION OF THE SERVICE, OR THE INFORMATION, CONTENT, MATERIALS,
        PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE
        MADE AVAILABLE TO YOU THROUGH THE SERVICE, UNLESS OTHERWISE SPECIFIED IN
        WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT YOUR
        SOLE RISK.
      </p>
      <p>
        TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, BEAUTYSHELF DISCLAIMS
        ALL WARRANTIES,EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
        IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
        PURPOSE. BEAUTYSHELF DOES NOT WARRANT THAT THE SERVICE, INFORMATION,
        CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES
        INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICE,
        BEAUTYSHELF’ SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM BEAUTYSHELF
        ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. BEAUTYSHELF WILL NOT BE
        LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE SERVICE,
        OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING
        SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO
        YOU THROUGH THE SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT,
        INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE
        SPECIFIED IN WRITING. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON
        IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF
        THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
        EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE
        ADDITIONAL RIGHTS.{' '}
      </p>
      <p>
        <b>Assignment. </b>
        This Agreement, and any rights and licenses granted hereunder, may not
        be transferred or assigned by you, but may be assigned by Beautyshelf
        without restriction.
      </p>
      <p>
        <b>No Waiver. </b>
        No waiver of any term of this Agreement shall be deemed a further or
        continuing waiver of such term or any other term, and Beautyshelf'
        failure to assert any right or provision under this Agreement shall not
        constitute a waiver of such right or provision.
      </p>

      <p>Please contact us with any questions regarding this Agreement.</p>
    </div>
  );
};

export default Terms;
