import { Link, Outlet, Route, Routes } from 'react-router-dom';
import Home from './pages/index';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Cookies from './pages/cookies';
import Header from './pages/header';
import Footer from './pages/footer';
import Support from './pages/support';
import { useCallback, useEffect, useRef, useState } from 'react';
import './pages/header.css';
import './app.css';

const Layout = () => {
  const [isVisible, setIsVisible] = useState(false);
  const supportRef = useRef(null);
  const burgerMenuSvgRef = useRef(null);

  const handleClickOutside = useCallback((event) => {
    // if (
    //   burgerMenuSvgRef.current &&
    //   supportRef.current.contains(burgerMenuSvgRef.current)
    // ) {
    //   console.log(burgerMenuSvgRef.current, 'lmao');
    // }

    if (
      supportRef.current &&
      !supportRef.current.contains(event.target) &&
      !burgerMenuSvgRef?.current?.contains(event.target)
    ) {
      setIsVisible(false); // Close the menu if clicked outside
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      console.log('use effect used to be visible');
      document.addEventListener('mousedown', handleClickOutside);
    }
    //  else {
    //   console.log('use effect used to be invisible');
    //   document.removeEventListener('mousedown', handleClickOutside);
    // }

    // Clean up the event listener when the component unmounts or isVisible changes
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, handleClickOutside]);
  return (
    <div
      // style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgb(248, 248, 248)',
      }}
    >
      <Header setIsVisible={setIsVisible} burgerMenuSvgRef={burgerMenuSvgRef} />

      {/* <div style={{ display: 'flex', flexGrow: 1, paddingTop: 75 }}> */}
      <div className="outlet-wrapper">
        {isVisible && (
          <div
            ref={supportRef}
            className="support-wrapper"
            //
            onClick={(e) => e.stopPropagation()}
          >
            <Link
              to="support"
              className="support"
              onClick={() => setIsVisible(false)}
            >
              Support
            </Link>
          </div>
        )}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
function App() {
  return (
    <Routes>
      {/* <Route path="terms-and-conditions" element={<Terms />} />
      <Route path="privacy-policy" element={<Privacy />} />
      <Route path="cookies-policy" element={<Cookies />} /> */}
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="terms-and-conditions" element={<Terms />} />
        <Route path="privacy-policy" element={<Privacy />} />
        <Route path="cookies-policy" element={<Cookies />} />
        <Route path="/support" element={<Support />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

const NoPage = () => {
  return (
    <div
      style={{
        paddingTop: 50,
        textAlign: 'center',
      }}
    >
      Oops! Page not found.
    </div>
  );
};
export default App;
