// import emailjs from '@emailjs/browser';
import { createRef, memo, useCallback, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './support.css';

const Support = memo(() => {
  const [popupMessage, setPopupMessage] = useState('');

  const recaptchaRef = createRef();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const handleChange = useCallback((e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }, []);

  const sendEmail = useCallback(
    async (e, fD) => {
      e.preventDefault();

      const recaptchaValue = recaptchaRef.current.getValue(); // Trigger reCAPTCHA manually
      if (!recaptchaValue) {
        setPopupMessage('Please complete the CAPTCHA verification');
        return;
      }

      const requestPayload = {
        token: recaptchaValue, // The reCAPTCHA token
        templateParams: fD,
      };
      try {
        const response = await fetch('/api/support', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestPayload),
        });
        if (response.ok) {
          setPopupMessage('Your form has been submitted successfully!');
          setFormData({
            name: '',
            email: '',
            message: '',
          });
        } else {
          setPopupMessage(
            'Something went wrong with your request. Please try again later.'
          );
        }
      } catch (error) {
        setPopupMessage(
          'Something went wrong with your request. Please try again later.'
        );
      } finally {
        recaptchaRef.current.reset();
      }
    },
    [recaptchaRef]
  );

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}
    >
      <form
        onSubmit={(val) => sendEmail(val, formData)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '80%',
          maxWidth: '400px',
          alignSelf: 'center',
          alignItems: 'center',

          paddingTop: 50,
        }}
      >
        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginRight: 'auto',
          }}
        >
          Name
        </label>
        <input
          type="text"
          name="name"
          required={true}
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '16px',
            marginBottom: '15px',
            boxSizing: 'border-box',
          }}
          value={formData.name}
          onChange={handleChange}
        />

        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginRight: 'auto',
          }}
        >
          Email
        </label>
        <input
          required={true}
          type="email"
          name="email"
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '16px',
            marginBottom: '15px',
            boxSizing: 'border-box',
          }}
          value={formData.email}
          onChange={handleChange}
        />

        <label
          style={{
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            marginRight: 'auto',
          }}
        >
          Message
        </label>
        <textarea
          required={true}
          value={formData.message}
          onChange={handleChange}
          minLength={10}
          name="message"
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '16px',
            minHeight: '100px',
            marginBottom: '15px',
            boxSizing: 'border-box',
          }}
        />
        <div className="recaptcha-wrapper">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LeqEFYqAAAAAK1SHfSOvCHM9iQJ7C22KPnLs0Tw"
            className="recaptcha"
            // style={{
            //   position: 'absolute',
            //   top: '50%',

            //   // width: '90%',
            //   // maxWidth: '450px',
            //   // backgroundColor: '#7bb6db',
            //   // padding: '10px',
            //   // zIndex: 50,
            //   // height: '50px',
            //   left: 0,
            //   right: 0,
            //   // marginLeft: 'auto',
            //   // marginRight: 'auto',
            //   // borderRadius: '5px',
            //   // display: 'flex',
            //   // alignItems: 'center',
            //   // justifyContent: 'center',
            //   // fontSize: '16px',
            // }}
            // style={{
            //   transform: 'scale(0.77)',
            //   transformOrigin: '0 0',
            // }}
          />
        </div>
        <div style={{ height: '20px' }} />
        <input
          type="submit"
          value="Send"
          style={{
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            backgroundColor: '#abd4eb',
            fontSize: '16px',
            fontWeight: 600,
            cursor: 'pointer',
          }}
        />
      </form>
      {popupMessage && (
        <div
          style={{
            position: 'absolute',
            top: '50%',

            width: '90%',
            maxWidth: '450px',
            backgroundColor: '#7bb6db',
            padding: '10px',
            zIndex: 50,
            height: '50px',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '16px',
          }}
          onClick={() => setPopupMessage('')}
        >
          <button
            onClick={() => setPopupMessage('')}
            style={{
              position: 'absolute',
              top: '5px',
              right: '10px',
              background: 'transparent',
              border: 'none',
              color: 'black',
              fontSize: '20px',
              cursor: 'pointer',
            }}
          >
            &times;
          </button>
          {popupMessage}
        </div>
      )}
    </div>
  );
});
Support.displayName = 'Support';
export default memo(Support);
