import { Link } from 'react-router-dom';

const Cookies = () => {
  return (
    <div
      style={{
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
      }}
    >
      <h1>Cookies Notice</h1>
      <p>
        We use cookies, pixels, and other technologies (collectively, “cookies”)
        to recognize your browser or device, learn more about your interests,
        and provide you with essential features and services and for additional
        purposes, including:
      </p>
      <p>
        {' '}
        - Recognizing you when you sign-in to use our services. This allows us
        to provide you with personalized content, recognize you as a member, and
        provide other customized features and services.
      </p>
      <p> - Keeping track of services you interact with.</p>
      <p>
        {' '}
        - Conducting research and diagnostics to improve our content, products,
        and services.
      </p>
      <p> - Preventing fraudulent activity.</p>
      <p> - Improving security.</p>
      <p>
        {' '}
        - Reporting. This allows us to measure and analyze the performance of
        our services.
      </p>
      <p>
        Beautyshelf cookies allow you to take advantage of some of our essential
        features. If you block or otherwise reject our cookies, you will not be
        able use any services that require you to sign in.
      </p>
      <p>
        Approved third parties may also set cookies when you interact with our
        services. Third parties include search engines, providers of measurement
        and analytics services, social media networks, and advertising
        companies. Third parties use cookies in the process of delivering
        content, including ads relevant to your interests, to measure the
        effectiveness of their ads, and to perform services on our behalf.
      </p>
      <p>
        You can manage browser cookies through your browser settings. The ‘Help’
        feature on most browsers will tell you how to prevent your browser from
        accepting new cookies, how to have the browser notify you when you
        receive a new cookie, how to block cookies, and when cookies will
        expire. If you block all cookies on your browser, neither we nor third
        parties will transfer cookies to your browser. If you do this, however,
        you may have to manually adjust some preferences every time you visit a
        site and some features and services may not work.
      </p>

      <p>
        See our
        <Link to="/privacy-policy"> Privacy Notice </Link>
        for more information about the types of information we gather.
      </p>
    </div>
  );
};

export default Cookies;
