import { Link } from 'react-router-dom';
import './header.css';
import { memo } from 'react';
import { IoReorderThree } from 'react-icons/io5';

const Header = memo(({ burgerMenuSvgRef, setIsVisible }) => {
  return (
    <div className="main-header-container">
      <header>
        <div className="header-top">
          <div className="header-container">
            <div
              ref={burgerMenuSvgRef}
              className="burger-icon"
              onClick={() => {
                console.log('meeeow');
                setIsVisible((prev) => !prev);
              }}
            >
              <IoReorderThree />
            </div>
            <Link
              className="header-logo"
              style={{
                // fontFamily: "'Bodoni Moda', serif",
                fontFamily: "'Lora', serif",
                userSelect: 'none',
                marginRight: 'auto',
                // marginBottom: '75px',
              }}
              to="/"
            >
              BeautyShelf
            </Link>
            {/* <div>Contact Us</div> */}
          </div>
          <Link
            className="support-big"
            // style={{
            //   // fontFamily: "'Bodoni Moda', serif",
            //   fontFamily: "'Lora', serif",
            //   userSelect: 'none',
            //   marginRight: 'auto',
            //   // marginBottom: '75px',
            // }}
            to="support"
          >
            Support
          </Link>
          {/* <div className="support-big">Support</div> */}
        </div>
        {/* {isVisible && ( */}
        {/* )} */}
      </header>
    </div>
  );
});
Header.displayName = 'Header';
export default memo(Header);
