import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div
      style={{
        paddingTop: 20,
        paddingBottom: 20,
        borderColor: 'rgb(240, 240, 240)',
        borderWidth: '1px',
        borderStyle: 'solid',
        // display: 'flex',
        // flexDirection: 'column',
        // justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center', marginBottom: 3 }}>
        {' '}
        Copyright © 2024 BeautyShelf. All Rights Reserved.
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
        <div style={{ paddingLeft: 3, paddingRight: 3 }}>·</div>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </div>
  );
};

export default Footer;
