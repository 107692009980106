import { Link } from 'react-router-dom';

const Privacy = () => {
  return (
    <div
      style={{
        maxWidth: '900px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '90%',
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        Beautyshelf (together with its affiliates, “Beautyshelf,” “we,” or “us”)
        knows that you care how information about you is used and shared, and we
        appreciate your trust that we will do so carefully and sensibly. By
        visiting or using Beautyshelf.com and related services (the “Service”),
        you are accepting the practices described in this notice (the “Privacy
        Notice”). Please note that, if you have an account on
        www.beautyshelf.com, information gathered by Beautyshelf may be used by
        Beautyshelf to improve the services we offer.
      </p>
      <h2>User Content and Messages</h2>
      <p>
        The Service allows you to post User Content, as defined in our Terms of
        Use. Any User Content you post becomes available to the public via the
        Service. User Content includes, but is not limited to, reviews,
        comments, and visual content. If you remove User Content, copies may
        remain viewable in cached and archived pages or if other users have
        copied or stored your User Content.
      </p>
      <h2>Privacy Settings</h2>
      <p>
        Your name is used when you invite another person to join Beautyshelf, or
        when you request to add another member to your friends list. To the
        extent that you do not wish to have your name shared in this manner, you
        should not make use of these services.
      </p>
      <h2>Your Choices About Your Information</h2>
      <p>
        To the extent required by applicable law, you may have the right to
        delete your personal information. To do so, you may visit here and
        navigate to the “Settings” tab or contact us. You may, of course,
        decline to submit personally identifiable information through
        Beautyshelf, in which case Beautyshelf may not be able to provide
        certain services to you.
      </p>
      <h2>Cookies and Other Identifiers</h2>
      <p>
        To enable our systems to recognize your browser or device and to provide
        and improve the Service, we use cookies and other identifiers. For more
        information about cookies and how we use them, please read our{' '}
        <Link to="/cookies-policy">Cookies</Link> notice.
      </p>
      <h2>Children's Privacy</h2>
      <p>
        Protecting the privacy of young children is especially important. For
        that reason, Beautyshelf does not knowingly collect or solicit personal
        information from anyone under the age of 13 or knowingly allow such
        persons to register. If you are under 13, please do not send any
        information about yourself to us, including your name, address,
        telephone number, or e-mail address. No one under age 13 is allowed to
        provide any personal information to or on Beautyshelf. In the event that
        we learn that we have collected personal information from a child under
        age 13 without verification of parental consent, we will delete that
        information as quickly as possible. If you believe that we might have
        any information from or about a child under 13, please contact us.
      </p>
    </div>
  );
};

export default Privacy;
