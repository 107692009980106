import './index.css';
const Home = () => {
  return (
    <div className="main">
      <div className="placeholder">
        <img
          // style={{ width: '100%', maxWidth: 500 }}
          // style={{ height: '90vh' }}
          className="flatlay"
          alt="Beauty products flatlay"
          src="flatlay.jpg"
        />
      </div>
      <div className="coming-soon-wrapper">
        <h1 className="coming-soon">Coming Soon</h1>
        <p style={{ textAlign: 'center' }}>
          We are working hard to bring our app to iOS and Android.
        </p>
      </div>
    </div>
  );
};

export default Home;
